<template>
  <a-layout class="content">
    <!-- menu 页面目录 -->
    <a-layout-sider class="sider" theme="light" v-model="collapsed">
      <SideMenu />
    </a-layout-sider>
    <a-layout>
      <!-- 头部 -->
      <a-layout-header class="header">
        <div class="uer-header">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <a-icon type="user" class="icon" />
              <span style="color: #555">{{ userName }}</span>
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="loginOut">登出</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <!-- 标签页面 -->
      <Tabs ref="tabs">
        <a-config-provider :locale="locale">
          <router-view />
        </a-config-provider>
      </Tabs>
    </a-layout>
  </a-layout>
</template>
<script>
import SideMenu from "@/components/SideMenu/SideMenu";
import Tabs from "@/components/Tabs";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { tips } from "@/utils/tips";
import { LoginAPi } from "@/api/login.js";
// import { mapState } from "vuex";
export default {
  components: { SideMenu, Tabs },
  data() {
    return {
      collapsed: false,
      locale: zhCN,
    };
  },
  computed: {
    isSider: function () {
      return false;
    },
    // ...mapState('Login',['userName'])
    userName() {
      return this.$store.state.Login.userName;
    },
  },
  methods: {
    // 删除本地数据去登录页
    async loginOut() {
      let res = await LoginAPi.logOut();
      if (res.success) {
        await this.$store.dispatch("Login/loginout");
        this.$router.push("/login");
        window.location.reload();
      }
      tips(res, "登出");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  // height: 100vh !important;
  .table {
    min-height: 569px;
    height: 100%;
    box-sizing: border-box;
    & > div {
      height: 100%;
    }
    padding: 10px 20px;
    background: #fff;
    margin: 0px 10px;
    min-height: 460px;
    position: relative;
    z-index: 10;
  }
}
.sider {
  border-right: 1px solid #e8e8e8;
  ul {
    border-right: none;
  }
}
// .ant-layout {
//   overflow-x: unset !important;
// }
.ant-layout > div {
  height: 100%;
  // overflow: auto;
  padding-top: 50px;
  position: relative;
  min-height: 500px;
}
.header {
  padding: 10px 36px;
  background: #fff;
  height: 48px;
  box-sizing: border-box;
  div {
    text-align: right;
    font-size: 20px;
    box-sizing: border-box;
    position: relative;
    top: -16px;
    font-size: 14px;
    color: #333333;
    cursor: default;
    .icon {
      font-size: 22px;
      color: #999999;
    }
  }
}
</style>
