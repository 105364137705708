<template>
  <div style="height: 100%">
    <div class="image-box">
      <img src="@/assets/image/clogo.png" />
    </div>
    <!-- <div v-if="Object.keys(currentIndex()).length"> -->
    <a-menu
      mode="inline"
      :selected-keys="[currentIndex().index ? currentIndex().index : 0]"
      :default-open-keys="[
        'sub' + (currentIndex().subindex ? currentIndex().subindex : 0),
      ]"
      class="slide-box"
    >
      <a-sub-menu v-for="(itemf, index) in preMenus()" :key="'sub' + index">
        <span slot="title"
          ><svg-icon :icon-class="itemf.icon" /><span
            style="margin-left: 7px"
            >{{ itemf.name }}</span
          ></span
        >
        <a-menu-item
          v-for="(items, index) in itemf.children"
          :key="itemf.pre + index"
          @click="jump(items, itemf.pre + index)"
        >
          <span style="margin-left: 7px">{{ items.name }}</span>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <!-- </div> -->
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("Menu");
import { MenuList, menuInfoFilter, addMenuList } from "@/enum/index.js";

export default {
  name: "menu",
  data() {
    return {
      sideList: [],
    };
  },
  computed: {
    ...mapState(["SiderMenuList", "ActiveKey"]),
  },
  methods: {
    // 页面跳转
    jump(url, index) {
      this.$router.push(url.path);
      this.current = index;
    },
    getLength(arr) {
      let i = 0;
      arr.forEach((fv) => {
        i = i + fv.children.length;
      });
      return i;
    },

    easyClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    // 拿到当前登陆用户信息
    userLoginInfo() {
      // 菜单信息处理
      // console.log("登录信息", this.$store.state.Login, this.SiderMenuList);

      // 重置menu菜单栏 (原因：一开始拿到的菜单列表已经被其他人在其他地方处理过了，解决：重置数组)
      this.SiderMenuList.map((item) => {
        item.children = [];
      });

      let res = this.$store.state.Login.roleIds;
      let result = menuInfoFilter(MenuList, res);

      if (result.length > 0) {
        // 跳转陆游
        history.replaceState(null, null, result[0].path);
      }

      // console.log(result, this.SiderMenuList);
      if (result.length < 1) {
        this.SiderMenuList = [];
      } else {
        this.sideList = addMenuList(result, this.SiderMenuList);
        // console.log(this.sideList);
      }
    },

    preMenus() {
      if (this.sideList.length < 1) {
        // this.sideList[0].pre =0
        return;
      } else {
        this.sideList.forEach((v, index) => {
          if (index >= 1) {
            let arr = this.sideList.slice(0, index);
            v.pre = this.getLength(arr);
          } else {
            v.pre = 0;
          }
        });
        return this.sideList;
      }
    },
    //加上pre属性
    currentIndex() {
      this.preMenus();
      let target = this.$route.meta.title;
      let index;
      let subindex;
      // let preMenus = this.preMenus();
      this.sideList.forEach((fv, findex) => {
        fv.children.forEach((sv, Sindex) => {
          if (sv.name == target) {
            subindex = findex;
            // console.log(Sindex, fv.pre);
            index = Sindex + fv.pre;
          }
        });
      });
      // console.log({ index, subindex });
      return { index, subindex };
      // return target
    },
  },
  created() {
    this.$nextTick(() => {
      this.userLoginInfo();
    });
  },
};
</script>
<style scoped lang="scss">
.image-box {
  padding: 10px 36px 4px;
}
/deep/.slide-box {
  height: calc(100% - 115px);
  display: block;
  overflow-y: auto !important;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    display: none;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
}
</style>
