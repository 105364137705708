<template>
  <div>
    <a-tabs
      class="content-table"
      v-model="Actives"
      hide-add
      type="editable-card"
      @edit="onEdit"
      @tabClick="changeTab"
    >
      <a-tab-pane
        v-for="pane in TabList"
        :key="pane.title"
        :tab="pane.title"
        :closable="isClose"
      >
      </a-tab-pane>
    </a-tabs>
    <div class="table">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("Menu");
export default {
  data() {
    return {
      panes: "",
      active: "",
    };
  },
  watch: {
    TabList: {
      handler(arr) {
        if (arr.length === 0) {
          return;
        }
        this.SetActiveKey(arr[arr.length - 1].title);
      },
    },
  },
  computed: {
    ...mapState(["TabList"]),
    Actives: {
      get() {
        return this.TabList?.filter((v) => v.path === this.$route.fullPath)[0]
          .title;
        // return this.$store.state.Menu.ActiveKey;
        // return this.$store.menu.state()
      },
      set(val) {
        this.$store.commit("Menu/SetActiveKey", val);
      },
    },
    isClose() {
      return this.TabList.length != 1;
    },
  },
  methods: {
    ...mapMutations(["SetActiveKey", "SetTabList", "SetCurrent"]),
    ...mapActions(["AsynSetTabList"]),

    onEdit(targetKey, action) {
      this[action](targetKey);
    },

    changeTab(e) {
      let path = this.TabList.filter((i) => i.title === e)[0].path;
      this.$router.push(path);
    },
    remove(targetKey) {
      let tabList = [...this.TabList];
      // let nowKey=this.Actives
      let index = tabList.findIndex((i) => i.title === targetKey);
      tabList.splice(index, 1);
      this.SetTabList(tabList);
      if (tabList.length === 0) {
        return;
      }
      let newpath = tabList[tabList.length - 1].path;
      this.$router.push(newpath);
      // this.$router.back();
    },
    // PaneClosable(data){
    //   console.log(data,'dataaaaaaa');
    // }
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.content-table {
  margin: 10px 29px;
  padding: 10px 0px;
  padding-bottom: 0px;
  position: absolute;
  top: 0;
}
.table {
  min-height: 569px;
  height: 100%;
  box-sizing: border-box;
  & > div {
    height: 100%;
  }
  padding: 10px 20px;
  // background: #fff;
  margin: 0px 10px;
  min-height: 460px;
  position: relative;
  z-index: 10;
}
/deep/.ant-tabs-nav-wrap {
  padding-left: 0px;
}
</style>
